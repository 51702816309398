/*
 * FOOTER
 */

.c-footer {
    background-color: $dark;

    p,
    a {
        color: $white;
    }

    .c-icon {
        fill: $white;
    }

    .c-icon-logo {
        width: 250px;
        height: 75px;
        margin: 0 auto;
    }

    hr {
        opacity: 0.15;
    }

    .c-footer-menu {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        @include media-query(md) {
            flex-direction: row;
        }

        .c-footer-menu-item {
            font-weight: bold;
            font-size: 0.95em;
            margin: 0 1.5rem;
        }
    }

    address {
        font-style: normal;
    }

    .c-footer-contact { 
        a,
        p,
        address {
            line-height: 1.6;
            font-size: 0.8rem;
            text-decoration: none;
        }
    }
}

.c-footer-bottom {
    p,
    a {
        font-size: 0.85em;
    }
}
