/*
 * HEADER
 */

.c-header {
    width: 100%;
    height: 80vh;
    position: relative;
}

.c-header-nav {
    position: absolute;
    width: 100%;
    z-index: 2;

    .c-hamburger {
        display: inline-block;

        @include media-query(md) {
            display: none;
        }
    }
}

.c-header-nav-logo {

    .c-icon-logo {
        width: 130px;
        height: auto;
        fill: $white;

        @include media-query(md) {
            width: 200px;
            height: auto;
        }
    }
}

.c-header-content {
    z-index: 1;

    .u-img-bg {
        z-index: 1;
    }

    .o-container {
        z-index: 2;
    }

    h1, h2, h3, h4, h5, h6, p, li, a {
        font-family: $h1-font-family;
        color: $white;
    }

    h5 {
        opacity: .5;
    }

    @include media-query(md) {
        h1 {
            margin-bottom: 16px;
        }
    }
}

body.cases {

    .c-header {
        height: 60vh;
    }
}

body.case, body.case-big {

    .c-header {
        height: 100vh;
    }
}

body.home, body.cases {

    .c-header {
        background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
        background-size: 400% 400%;
        animation: gradientBG 15s ease infinite;
    }
}

@keyframes gradientBG {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}
