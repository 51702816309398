/*
 * NAVIGATION
 */

// Mobile

// GENERAL

.c-nav {
    position: relative;
}

.c-nav-item {
    color: $white;
    font-family: $h1-font-family;
    font-weight: bold;
    position: relative;
    line-height: 1;
    text-decoration: none;
    font-size: 1.15rem;

    &:after {
        content:"";
        width: 100%;
        height: 2px;
        position: absolute;
        background-color: $white;
        left: 0;
        bottom: -8px;
        opacity: 0;
        transition: opacity .25s;
    }

    &:hover {
        text-decoration: none !important;

        &:after {
            opacity: 1;
        }
    }
}

.c-nav-item-active {

    &:after {
        opacity: 1;
    }
}

// Main

.c-nav-main {
    margin-bottom: 3rem;
}
