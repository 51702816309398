.c-case-essencia-img-left {
	@include media-query(xl) {
		.o-container {
			position: relative;
			.o-col {
				position: absolute;
				margin-top: 5%;
				z-index: 5;
				right: 0;
				color: $white;
				h2 {
					color: $white;
				}
			}
		}
	}
	img {
		height: auto;
		width: 100%;
		display: block;
	}
}

.c-case-essencia-gray {
	background-color: #e2e2e2;

	.c-case-essencia-gray-img {
		width: 100%;
		height: auto;
		display: block;
		z-index: 10;
	}
}

.c-case-essentia-green {
	background: rgb(139, 175, 111);
	background: linear-gradient(90deg, rgba(139, 175, 111, 1) 0%, rgba(117, 161, 84, 1) 100%);
	z-index: 5;
	padding-top: 100px;
	margin-top: -100px;
	h2 {
		color: $white;
	}
	position: relative;
	@include media-query(xl) {
		.o-container {
			position: relative;
			.o-col {
				position: absolute;
				margin-top: 5%;
				z-index: 5;
			}
		}
	}
	img {
		width: 100%;
		height: auto;
		display: block;
	}
	color: $white;
	@include media-query(xxl) {
		padding-top: 500px;
		margin-top: -400px;
	}
}

.c-case-pfizer {
	background-color: #008acb;
	color: $white;

	position: relative;

	h2 {
		color: $white;
	}

	@include media-query(xl) {
		height: 700px;

		.o-col {
			margin-top: 15%;
		}
		img {
			display: block;
			position: absolute;
			right: 0;
			bottom: 0;
			width: 65%;
			height: auto;
		}
	}
}

.c-case-ow {
	z-index: 1;
	margin-top: -30%;

	@include media-query(lg) {
		margin-top: -75%;
	}

	.o-container {
		position: relative;
		.o-col {
			position: absolute;
			margin-top: 10%;
			z-index: 5;

			@include media-query(xs) {
				margin-top: 40%;
			}

			@include media-query(sm) {
				margin-top: 60%;
			}

			@include media-query(lg) {
				margin-top: 75%;
			}
			@include media-query(xl) {
				margin-top: 100%;
			}
		}
	}
	&2 {
		.o-container {
			@include media-query(lg) {
				bottom: 25%;
				position: absolute;
				@include media-query(xxl) {
					bottom: 25%;
				}
				z-index: 5;
				.o-col {
					position: absolute;
					z-index: 5;
					margin-top: 0;
				}
			}
		}
	}
	&3{
		background-color: #93cec3;
		img{
			width: 100%;
			height: auto;
			
			z-index: 5;
			margin-top: -30%;
			@include media-query(xl){
				margin-top: -20%;
			}
			@include media-query(xxl){
				margin-top: -20%;
			}
			@include media-query(xxxl){
				margin-top: -30%;
			}
		}
	}
}
