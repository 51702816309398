/*
 * MAIN
 */

#main.c-main {
}

.c-section {
	display: block;
	position: relative;
	width: 100%;
}

.c-section-light {
	background-color: $light;
}

.c-section-green {
	background-color: #5bbca8;

	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	p {
		color: $white;
	}
}

.c-section-green-angle {
	&:before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: linear-gradient(to left top, #5bbca8 50%, transparent 50%);
	}
}

.c-section-cases {
	.o-col {
		opacity: 0;
	}
}

.c-section-clients {
	img {
		filter: grayscale(100%);
	}
}

.c-section-quotes {
	h3,
	h6 {
		font-family: $gilroy;
		line-height: 1.25;
	}

	h6 {
		opacity: 0.5;
	}
}

.c-section-cta {
}

.c-section-prev-next {
	.c-card {
		transition: background-color 0.333s ease;

		&:hover {
			background-color: $light;
		}
	}
}

.c-section-googlemaps {
	position: relative;
	width: 100%;
	height: auto;
	display: block;

	filter: brightness(105%) contrast(105%) saturate(0) blur(0) hue-rotate(0deg);

	&:before {
		content: "";
		position: absolute;
		background-color: $dark;
		width: 100%;
		height: 75%;
		display: block;
		bottom: 0;
	}
}

.c-section-prev-next {
	h5 {
		font-family: $gilroy;
	}

	p {
		color: $primary;
		font-weight: 700;
	}

	.c-icon {
		fill: $primary;
		width: 20px;
	}

	.c-card {
		background-color: #ffffff;
		transition: box-shadow 0.333s ease;

		&:hover {
			background-color: #ffffff;
			box-shadow: 0 6px 16px rgba(black, 0.1);
		}
	}
}

[v-cloak] {
	display: none;
}

// CASES

.c-section-img-neg {
	position: relative;
	z-index: 5;
	@include media-query(xl) {
		.o-container {
			position: relative;
			.o-col {
				position: absolute;
				margin-top: 20%;
				z-index: 5;
			}
		}
	}
	img {
		width: 100%;
		height: auto;
		display: block;
	}
	&--medium {
		@include media-query(xl) {
			.o-container {
				position: relative;
				.o-col {
					position: absolute;
					margin-top: 10%;
					z-index: 5;
				}
			}
		}
	}
}

.c-section-img-full {
	position: relative;
	img {
		width: 100%;
		height: auto;
	}
}

.c-section-numbers {
	h3 {
		margin-bottom: 0;
		@include interpolate(
			font-size,
			map-get($breakpoints, xs),
			map-get($breakpoints, xxxl),
			$h1-font-size-min,
			$h1-font-size-max
		);
	}
}

