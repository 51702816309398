/*
 * SETTINGS
 */

// GRID
$columns: 12;
$gutter: 16/16*1rem; 

$breakpoints: (
    xs: 480/16*1rem,    // 30rem
    sm: 640/16*1rem,    // 40 rem
    md: 960/16*1rem,    // 60 rem
    lg: 1024/16*1rem,   // 64 rem
    xl: 1140/16*1rem,    // 71.25 rem
    xxl: 1280/16*1rem,
    xxxl: 1690/16*1rem
);

// SPACING
$spacing: (
    auto: auto,
    none: 0/16*1rem,
    xs: 8/16*1rem,      // 0.5 rem
    sm: 16/16*1rem,     // 1.0 rem
    md: 24/16*1rem,     // 1.5 rem
    lg: 32/16*1rem,     // 2.0 rem
    xl: 40/16*1rem,     // 2.5 rem
    xxl: 80/16*1rem     // 5 rem
);

// COLORS
$info: #000000;
$success: #4caf50;
$warning: #ffc107;
$error: #e70638;

$dark: #161922;
$medium: #4A4A4A;
$grey: #d6d6d6;
$light: #fafafa;        // rgba(238, 238, 238, 1)
$white: #ffffff;

$primary: #006eb7;      // rgba(253, 153, 0, 1)
$secondary: #006eb7;    // rgba(198, 3, 127, 1)
$tertiary: #006eb7;     // rgba(35, 125, 192, 1)

// SOCIAL MEDIA
$linkedin: #0077b5;
$facebook: #3b5998;
$twitter: #1da1f2;
$instagram: #c13584;

// BORDER RADIUS
$border-radius: 1px;

// TYPOGRAPHY
@import url('https://fonts.googleapis.com/css?family=Be+Vietnam:400,500,700&display=swap');
@include fontFace('Gilroy-ExtraBold','../fonts/gilroy/gilroy-extrabold-webfont');
$gilroy: 'Gilroy-ExtraBold', Helvetica, sans-serif;

$min: 0.85;

$base-font-family: 'Be Vietnam', Helvetica, sans-serif;
$base-font-size-min: 1.1rem * $min;
$base-font-size-max: 1.1rem;
$base-font-weight: normal;
$base-line-height: 1.6;
$base-color: $dark;

$h1-font-family: $gilroy;
$h1-font-size-min: 3rem * $min;
$h1-font-size-max: 4.5rem;
$h1-font-weight: bold;
$h1-line-height: 1.5;
$h1-color: $dark;

$h2-font-family: $gilroy;
$h2-font-size-min: 2.25rem * $min;
$h2-font-size-max: 2.25rem;
$h2-font-weight: 500;
$h2-line-height: 1.2;
$h2-color: $dark;

$h3-font-family: $gilroy;
$h3-font-size-min: 1.875rem * $min;
$h3-font-size-max: 1.875rem;
$h3-font-weight: 400;
$h3-line-height: 1.5;
$h3-color: $dark;

$h4-font-family: $gilroy;
$h4-font-size-min: 1.625rem * $min;
$h4-font-size-max: 1.625rem;
$h4-font-weight: 400;
$h4-line-height: 1.5;
$h4-color: $dark;

$h5-font-family: $gilroy;
$h5-font-size-min: 1.35rem * $min;
$h5-font-size-max: 1.35rem;
$h5-font-weight: 400;
$h5-line-height: 1.5;
$h5-color: $dark;

$h6-font-family: $gilroy;
$h6-font-size-min: 1.15rem * $min;
$h6-font-size-max: 1.15rem;
$h6-font-weight: 400;
$h6-line-height: 1.5;
$h6-color: $dark;
