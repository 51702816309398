/*
 * CARDS
 */

.c-card-grid {

    > .o-col {
        margin-bottom: $gutter;
    }
}

.c-card {
    position: relative;
    background: $white;
    border-radius: $border-radius;
    transition: all .15s ease;
    cursor: pointer;
    height: 100%;

    h6 {
        opacity: .75;
    }

    a {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        text-indent: 200%;
        white-space: nowrap;
        font-size: 0;
        opacity: 0;
    }

    img {
        filter: brightness(100%);
        transition: all .33s ease;
        object-fit: cover;
    }

    &:hover {

        img {
            filter: brightness(85%);
            transform: scale(1.15);
        }

        .c-link-chevron::after {
            left: 1em;
        }
    }
}

.c-card-text {
    border: 1px solid $grey;
}

.c-card-figure {
    position: relative;
    overflow: hidden;
    background-color: $dark;
    width: 100%;
    height: auto;

    figure {
        width: 100%;
        height: auto;

        img {
            width: 100%;
            height: auto;
            object-fit: cover;
        }
    }

    figcaption {
        position: absolute;
        display: block;
        bottom: 0;
        width: 100%;

        .u-text-container{
            width: 100%;

            h3 {
                font-family: $h1-font-family;
            }

            h1, h2, h3, h4, h5, h6, p {
                color: $white;
            }
        }
    }
}

.c-card-figure-text {
    background-color: $dark;
    border: 1px solid $primary;

    figcaption {
        background-color: $white;
    }
}
