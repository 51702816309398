/*
 * VENDORS
 */

@import 'vendor/processwire/processwire.cookies';

@import 'vendor/slick/slick';
@import 'vendor/slick/slick-theme';

//@import "vendor/magnificpopup";

//@import "vendor/vue-slider-component";
