.c-content-05 {

  svg {
    width: 100%;
    display: block;
  }

  img{
    max-width:60%;
  }

  @include media-query(lg) {
    margin-top: 200px;
    img {
      margin-top: -150px;
      max-width:100%;
    }
  }
}

.c-content-06 {
  img{
    max-width:60%;
  }

  @include media-query(lg) {
    img {
      margin-top: -250px;
      max-width: 100%;
    }
    .u-text-container{
      margin-top: -100px;
    }
  }
}

.c-content-07{
  @include media-query(lg) {
    img {
      margin-top: -150px;
      max-width: 100%;
    }
  }
}