/*
 * BUTTONS
 */

.c-button {
    display: inline-block;
    padding: .5em 1em;
    background-color: $white;
    border-radius: $border-radius;
    transition: all .1s ease;
    font-family: $h1-font-family;
    font-weight: bold; 
    color: $dark;
    text-decoration: none;
    text-align: center; 
    font-size: 1.25rem;

    &:hover {
        background-color: darken($white, 5%);
        text-decoration: none;
        color: $dark;
    }
}

.c-button-primary {
    background-color: $dark;
    color: $white;

    &:hover {
        background-color: lighten($dark, 5%);
        color: $white;
    }
}

.c-button-success {
    background-color: $success;
    color: #fff;

    &:hover {
        background-color: lighten($success, 5%);
        color: $white;
    }
}

.c-button-warning {
    background-color: $warning;
    color: $white;

    &:hover {
        background-color: lighten($warning, 5%);
        color: $white;
    }
}

.c-button-error {
    background-color: $error;
    color: $white;

    &:hover {
        background-color: lighten($error, 5%);
        color: $white;
    }
}

.c-button-secondary {
    background-color: $secondary;
    color: $white;

    &:hover {
        background-color: lighten($secondary, 5%);
        color: $white;
    }
}

.c-button-tertiary {
    background-color: $tertiary;
    color: $white;

    &:hover {
        background-color: lighten($tertiary, 5%);
        color: $white;
    }
}

// Links

.c-link-chevron {
    font-weight: 700;
}

.c-link-chevron::after {
    border-style: solid;
    border-width: 0.085em 0.085em 0 0;
    content: '';
    display: inline-block;
    height: .5em;
    left: .5em;
    position: relative;
    top: .6em;
    transform: rotate(45deg);
    transition: all .15s ease;
    vertical-align: top;
    width: .5em;
}
