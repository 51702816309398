/*
 * TEXT
 */

.u-text-container {
    > *:last-child {
        margin-bottom: 0;
    }

    ul{
        list-style-type: none;
        li {
            list-style-type: none;
            margin-left: 1rem;
            &:before {
                content: "\2022";
                color: $primary;
                font-weight: bold;
                display: inline-block;
                width: 1rem;
                margin-left: -1rem;
            }
        }
    }
}

.u-text-2-columns {

    @each $bp in map-keys($breakpoints) {
        @include media-query($bp) {
            &-#{$bp} {
                column-count: 2;
                column-gap: 32px;
            }
        }
    }
}

.u-text-3-columns {

    @each $bp in map-keys($breakpoints) {
        @include media-query($bp) {
            &-#{$bp} {
                column-count: 3;
                column-gap: 32px;
            }
        }
    }
}

.u-text-4-columns {

    @each $bp in map-keys($breakpoints) {
        @include media-query($bp) {
            &-#{$bp} {
                column-count: 4;
                column-gap: 40px;
            }
        }
    }
}

.u-text-left {
    text-align: left;

    @each $bp in map-keys($breakpoints) {
        @include media-query($bp) {
            &-#{$bp} {
                text-align: left !important;
            }
        }
    }
}

.u-text-right {
    text-align: right;

    @each $bp in map-keys($breakpoints) {
        @include media-query($bp) {
            &-#{$bp} {
                text-align: right !important;
            }
        }
    }
}

.u-text-center {
    text-align: center;

    @each $bp in map-keys($breakpoints) {
        @include media-query($bp) {
            &-#{$bp} {
                text-align: center !important;
            }
        }
    }
}
