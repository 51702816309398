.c-accordion {
	&__item {
		margin-bottom: 1em;
		width: 100%;
		display: block;
	}

	&__title {
		padding: 1em 1.6em;
		cursor: pointer;
		background-color: $light;
		transition: all 0.3s ease-out;
		border-bottom: 2px solid $primary;

		h4{
			font-size: 1.1rem;
		}

		&.active {
			.c-icon {
				transform: scaleY(-1);
			}
		}

		.c-icon {
			fill: $primary;
			transition: all 0.3s ease-out;
		}
		h4 {
			color: $dark;
			margin-bottom: 0;
			transition: all 0.3s ease-out;
		}
	}
	&__content {
		padding: 2rem;
		@include media-query(md){
			padding: 4rem;
		}
		display: none;
		background-color: $light;
	}
}
