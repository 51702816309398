/*
 * BLOCKS
 */

.c-block {

    h6 {
        opacity: .5;
        text-transform: uppercase;
        font-size: .75em;
    }
}

.c-block-image_single { 

    img {
        width: 100%;
        height: auto; 
    }
}

.c-categories-list {

    img {
        width: 24px;
    }
}
